import React from "react"
import { Link } from 'gatsby'

import Layout from "../components/layout"
import Band, { BandColors } from "../components/band"
import HeroBand from "../components/hero-band/hero-band"
import SEO from "../components/seo"
import styles from "./index.module.css"
import sharedStyles from "../shared/shared.module.css"

import Check from "../icons/check.svg"
import User from "../icons/user.svg"
import Gears from "../icons/gears.svg"
import Lightning from "../icons/lightning.svg"
import App from "../icons/app.svg"

const subSectionColData = [
  {
    key: 1,
    className: styles.subSectionCheck,
    Icon: Check,
    title: 'QUALITY',
    desc: `From the code to the user experience a quality product is our number one priority.`
  },
  {
    key: 2,
    className: styles.subSectionUser,
    Icon: User,
    title: 'CUSTOMER SERVICE',
    desc: `Contact us to speak directly to the experts who developed your product. `
  },
  {
    key: 3,
    className: styles.subSectionGears,
    Icon: Gears,
    title: 'AUTOMATION',
    desc: `Automation is used in all levels of our development process to ensure quality.`
  }
];

const SubSectionColumn = ({ className, Icon, title, desc }) => (
  <div className={styles.subSectionCol + ' ' + className}>
    <div className={styles.subSectionCircle}>
      <div className={styles.subSectionIcon}>
        <Icon />
      </div>
    </div>
    <h4 className={styles.subSectionColTitle}>
      {title}
    </h4>
    <div className={styles.subSectionColText}>
      {desc}
    </div>
  </div>
);

const SubSectionColumns = () => subSectionColData.map((data) => (
  <SubSectionColumn
    key={data.key}
    className={data.className}
    Icon={data.Icon}
    title={data.title}
    desc={data.desc}
  />
));

const SubSection = () => (
  <Band className={styles.subSectionBand}>
    <section className={styles.subSectionWrapper}>
      <SubSectionColumns />
    </section>
  </Band>
);

const ProductsSection = () => (
  <Band color={BandColors.orange}>
    <section className={styles.productsSection}>
      <h2>App Exchange Products</h2>
      <p>Our products are available ONLY on the Salesforce App Exchange to ensure the industry standards for security are met.</p>
      <div className={styles.productsRow}>
        <div className={styles.productsCol}>
          <div className={styles.productsIcon}>
            <Lightning />
          </div>
          <p>
            Lightning Components
            </p>
        </div>
        <div className={styles.productsCol}>
          <div className={styles.productsIcon}>
            <App />
          </div>
          <p>
            Lightning Apps
            </p>
        </div>
      </div>
      <Link to="/products/" className={`${sharedStyles.btn} ${sharedStyles.btnLight}`}>
        View Our Products
      </Link>
    </section>
  </Band>
);

const ContactSection = () => (
  <Band>
    <section className={styles.contactSection}>
      <div className={styles.contactIcon}>
        ?
      </div>
      <h2 className={styles.contactText}>Questions about our products or services?</h2>
      <div className={styles.contactButton}>
        <a href="mailto: contact@tigerfacesystems.com" className={`${sharedStyles.btn} ${sharedStyles.btnPrimary}`}>
          Send Us An Email
        </a>
      </div>
    </section>
  </Band>
);

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`tigerface`, `systems`, `salesforce`]} />

    <HeroBand>
      Specializing in quality products and <br className={sharedStyles.hiddenMobile}/> 
      outstanding customer service.
      <div className={styles.heroButton}>
        <Link to="/products/" className={`${sharedStyles.btn} ${sharedStyles.btnPrimary}`}>
          View Our Products
        </Link>
      </div>
    </HeroBand>
    <div className={styles.mobileDivider}>&nbsp;</div>
    <SubSection />
    <ProductsSection />
    <ContactSection />
  </Layout>
)

export default IndexPage
