import React from "react"
import styles from "./hero-band.module.css"

import img from "../../images/sf-home-page.png"

const HeroBand = ({ children }) => (
  <div className={styles.heroBand}>
    <img src={img} alt="Hero" className={styles.heroImage} />
    <div className={styles.heroImageOverlay}></div>
    <div className={styles.heroImageTextContainer}>
      <div className={styles.heroImageText}>
        {children}
      </div>
    </div>
  </div>
);

export default HeroBand;